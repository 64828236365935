<template>
  <el-main class="simple1-container">
    <section class="inner-container">
      <div class="section-h2">
        <MainContain />
        HAHAGO 全民365 Sports Everywhere
      </div>
      <p>2024年3月</p>
      <div class="image-container">
        <img :src="image" class="image" alt="線上運動發展健康職場認證顧問服務">
        <p class="image_desc">線上運動發展健康職場認證顧問服務</p>
        <p class="image_desc">圖片為HAHAGO線上運動會宣傳圖片</p>
        <p class="image_desc">(圖片提供：鉅碼科技)</p>
      </div>
      <div class="content-section">
        <div class="section-h3">實證目標</div>
        <div class="content-desc">
          運動企業認證舉辦8年來，累計獲證企業家數達728家，影響職工總人數超過113萬人，顯示出運動在企業上正向的影響力，鉅碼科技以簡單運動習慣養成理念，鼓勵企業能透過免費授權方式獲得推廣健康促進『短期健促 線上運動會、長期健促 運動數據追蹤』的平台功能，協助企業可以達到員工健促與公益之ESG 綜效，同時為HAHAGO 平台帶來永續運營的運動流量收入。
        </div>
      </div>
      <div class="content-section">
        <div class="section-h3">數據貢獻活動</div>
        <div class="content-desc">
          鉅瑪科技、中華生技醫藥行業協會一同辦理「HAHAGO企業線上運動會」，供給團體與協會做推廣與應用，以降低企業團體因經費預算問題的門檻，實踐健康職場員工健康促進之理念。
        </div>
        <div class="content-desc">
          於112年8月14日至11月9日期間執行，共完成累計5.2萬人報名及38萬筆運動生理數據，數據包含性別、年齡、步數、體重、身高等運動生理數據，完整數據留存原始資料於hahago既有運動平台中，並已與運動數據公益平台串接。
        </div>
      </div>
      <div class="content-section">
        <div class="section-h3">數據應用模式</div>
        <div class="content-desc">
          鼓勵企業團體健促活動，舉辦線上運動會收集與追蹤運動數據，達成企業員工健促成效。
        </div>
      </div>
      <div class="content-section">
        <div class="section-h3">數據應用效益</div>
        <div class="content-desc">
          應用運動數據結合顧問知識，協助企業取得國內外認證，促進ESG/SDGs實踐。
        </div>
      </div>
    </section>
  </el-main>
</template>

<script>
import MainContain from '@/components/Accessibility/MainContain.vue'

export default {
  name: 'UseCase11205',
  components: {
    MainContain
  },
  data () {
    return {
      image: require('@/assets/images/sample/112-05.jpg')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/styles/index.scss";
.simple1-container{
  .inner-container{
    max-width: 800px;
    margin: 0 auto;
    padding: 1.25rem 0;
  }
  .image-container{
    padding: 1.5rem 0;
    .image {
      width: 80%;
      display: block;
      margin: 0 auto;
      }
    .image_desc{
      text-align: center;
    }
  }
  
  .content-section{
    
    .content-desc{
      line-height: 1.6;
    }
  }
}

</style>
